import { ThemeOptions } from '@mui/material'

import { fontFamilyHeadings, fontFamilyPrimary } from './fontFamily'

const primary = '#f3009d'
const secondary = '#cccccc'

export const themeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
  typography: {
    body1: {
      fontFamily: fontFamilyPrimary,
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1rem',
    },
    fontFamily: fontFamilyPrimary,
    h1: {
      fontFamily: fontFamilyHeadings,
      fontSize: '3.2rem',
      marginBottom: '0.8rem',
      marginTop: '1.6rem',
    },
    h2: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2.8rem',
      marginBottom: '0.7rem',
      marginTop: '1.4rem',
    },
    h3: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2.24rem',
      marginBottom: '0.56rem',
      marginTop: '1.12rem',
    },
    h4: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2rem',
      marginBottom: '0.5rem',
      marginTop: '1rem',
    },
    h5: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1.6rem',
      marginBottom: '0.4rem',
      marginTop: '0.8rem',
    },
    h6: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1.2rem',
      marginBottom: '0.3rem',
      marginTop: '0.6rem',
    },
    subtitle1: {
      fontFamily: fontFamilyHeadings,
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontSize: '0.85rem',
      opacity: '50%',
    },
  },
}
