import { Box } from '@mui/material'
import { InvertableThemeProvider } from '@xylabs/sdk-react'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Error404, Home } from '../Pages'
import { themeOptions } from '../theme'
import { checkDomain } from './checkDomain'

checkDomain()

export const App: React.FC = () => {
  return (
    <InvertableThemeProvider options={themeOptions}>
      <BrowserRouter>
        <Box className="App">
          <Box>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route element={<Error404 />} path="*" />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </InvertableThemeProvider>
  )
}
