import { useTheme } from '@mui/material'
import { BasePage, FlexCol, FlexRow } from '@xylabs/sdk-react'
import React, { useEffect, useState } from 'react'

import elonImage from '../img/elon.png'

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

function useCurrentWidth() {
  // save current window width in the state object
  const [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      setTimeout(() => setWidth(getWidth()), 150)
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

export const Home: React.FC = () => {
  const theme = useTheme()
  const width = useCurrentWidth()
  let height = getHeight()
  const vertical = height > width

  if (vertical) {
    height = width * 1.78
  } else {
    height = width * 0.56
  }

  return (
    <BasePage appBar={<FlexRow />} bgcolor={theme.palette.primary.main}>
      <FlexCol width={width}>
        <FlexCol height={height}>
          <img src={elonImage} width={300} />
        </FlexCol>
      </FlexCol>
    </BasePage>
  )
}
