import { Box, Container, Link, Typography } from '@mui/material'
import { BasePage } from '@xylabs/sdk-react'
import React from 'react'

import { CustomAppBar } from '../CustomAppBar'

export const Error404: React.FC = () => {
  return (
    <BasePage appBar={<CustomAppBar />}>
      <Container>
        <Box display="flex" flexDirection="column" justifyContent="center" minHeight="100vh" padding={2}>
          <Box color="orange" display="flex" justifyContent="center" padding={2}>
            <Typography variant="h1">404</Typography>
          </Box>
          <Box display="flex" justifyContent="center" padding={2}>
            <Typography variant="h2">Fuck Off</Typography>
          </Box>
          <Box display="flex" justifyContent="center" padding={2}>
            <Typography variant="h3">
              Go <Link href="/">Home</Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </BasePage>
  )
}
